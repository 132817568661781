<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             body-class="modal-chats pb-0"
             content-class="rounded-xl border-0 shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <h1 class="font-weight-bolder mb-4">
            Select Chat{{ isMultiple ? "s" : "" }}
        </h1>

        <form v-on:submit.prevent.stop="submit">
            <b-form-checkbox-group class="list-group"
                                   size="lg"
                                   v-model="options">
                <div class="list-group-item p-0"
                     v-for="chat in chats"
                     v-bind:key="chat._id">
                    <b-form-checkbox class="w-100 pl-5 m-0"
                                     v-bind:disabled="isMultiple ? false : optionIds.includes(chat._id) ? false : options.length > 0"
                                     v-bind:value="chat">
                        <h1 class="font-weight-bolder h5 mb-0">
                            {{ chat.name }}
                        </h1>
                        <p class="mb-0">
                            <small>
                            <span class="badge badge-whatsapp mr-1"
                                  v-if="chat.type === 'whatsapp' || !chat.type">
                                <font-awesome-icon v-bind:icon="['fab', 'whatsapp']"></font-awesome-icon> WhatsApp
                            </span>
                                <span class="badge badge-info mr-1"
                                      v-if="chat.type === 'telegram'">
                                <font-awesome-icon v-bind:icon="['fab', 'telegram']"></font-awesome-icon> Telegram
                            </span>
                            </small>
                        </p>
                    </b-form-checkbox>
                </div>
            </b-form-checkbox-group>

            <div class="position-sticky buttons text-center py-3">
                <button type="submit"
                        class="btn btn-primary rounded-pill shadow-sm px-4 mr-2"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Confirm
                </button>
                <button type="reset"
                        class="btn btn-secondary rounded-pill shadow-sm px-4"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    ♻️ Reset
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { BModal, BFormCheckboxGroup, BFormCheckbox, } from "bootstrap-vue";
export default {
    name: "ModalChats",
    components: {
        BModal, BFormCheckboxGroup, BFormCheckbox,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "selected": {
            type: Array,
        },
        "isMultiple": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isLoading: false,

            options: [],
        };
    },
    computed: {
        chats () {
            return this.$store.getters["whatsapp/chats"];
        },
        optionIds () {
            return this.options.map(({ _id }) => _id);
        },
    },
    methods: {
        hideModal() {
            this.$emit("input", false);
        },
        submit () {
            this.$emit("update:selected", this.options);
            this.hideModal();
            this.$emit("selected");
            this.options = [];
        },
    },
}
</script>

<style lang="scss" scoped>
.badge-whatsapp {
    background-color: #00bfa5;
    color: rgba(255, 255, 255, 1);
}

.buttons {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 3
}
</style>

<style lang="scss">
.modal-chats {
    .custom-control-input {
        width: 100%;
        height: 100%;
    }

    .custom-control-label {
        width: 100%;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 1.25rem;

        &:before,
        &:after {
            top: 50% !important;
            transform: translateY(-50%);
        }
    }
}
</style>
